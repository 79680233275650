<template>
  <div>
    <div v-if="!hasRoundStarted" class="m-auto col-md-12 col-lg-8 col-xl-6">
      <div class="alert alert-warning">
        Please start the round before viewing the leaderboard
      </div>
    </div>
    <div v-else>
      <div class="col-10 m-auto">
        <h4>Total Points</h4>
        <b-table
          striped
          class="table text-center"
          :fields="totalFields"
          :items="totals"
          small
        >
          <template #cell(player)="data"> {{ data.item.player }} </template>
          <template #cell(shots)="data"> {{ data.item.score }} </template>
          <template #cell(points)="data"> {{ data.item.points }} </template>
        </b-table>
      </div>

      <div class="col-10 m-auto">
        <h4>Shots Summary</h4>
        <b-table
          striped
          class="table text-center"
          :fields="players"
          :items="leaderboard"
        >
          <template #cell(hole)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            {{ data.value.score }} / {{ data.value.par }}
          </template>
        </b-table>
      </div>

      <div class="col-10 m-auto">
        <h4>Points Summary</h4>
        <b-table
          striped
          class="table text-center"
          :fields="players"
          :items="leaderboard"
        >
          <template #cell(hole)="data">
            {{ data.value }}
          </template>
          <template #cell()="data"> {{ data.value.points }} </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "MatchLeaderboard",
  methods: {},
  computed: {
    ...Vuex.mapGetters(["scorecard", "course"]),

    hasRoundStarted() {
      if (
        this.scorecard &&
        this.scorecard.players &&
        this.scorecard.players.length
      ) {
        return true;
      }
      return false;
    },

    fields() {
      const fields = ["Hole"];
      if (this.hasRoundStarted) {
        for (let i = 0; i < this.scorecard.players.length; i++) {
          const player = this.scorecard.players[i];
          fields.push(player.name);
        }
      }
      return fields;
    },

    totals() {
      const totals = [];
      for (let i = 0; i < this.scorecard.players.length; i++) {
        const player = this.scorecard.players[i];
        const total = {
          player: player.name,
          score: 0,
          points: 0,
        };
        let totalPoints = 0;
        let totalScore = 0;
        for (let j = 0; j < player.holes.length; j++) {
          const hole = player.holes[j];
          const { score, points } = hole;
          totalPoints += points;
          totalScore += score;
        }
        total.score = totalScore;
        total.points = totalPoints;
        totals.push(total);
      }
      totals.sort((a, b) => {
        if (a.totalPoints === b.totalPoints) {
          if (a.totalScore === b.totalScore) {
            return 0;
          }
          if (a.totalScore < b.totalScore) {
            return -1;
          }
          return 1;
        }

        if (a.totalPoints < b.totalPoints) {
          return -1;
        }
        return 0;
      });
      return totals;
    },

    totalFields() {
      return ["Player", "Shots", "Points"];
    },

    leaderboard() {
      const rows = [];
      if (
        this.course &&
        this.scorecard &&
        this.scorecard.players &&
        this.scorecard.players.length
      ) {
        for (let i = 0; i < this.course.holes.length; i++) {
          const hole = this.course.holes[i];
          const row = {
            hole: hole.name,
          };
          for (let j = 0; j < this.scorecard.players.length; j++) {
            const player = this.scorecard.players[j];
            const playerHole = player.holes[i];
            const { par, score, points } = playerHole;
            const playerKey = player.name;
            row[playerKey] = {
              par,
              score,
              points,
            };
          }
          rows.push(row);
        }
      }
      return rows;
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped type="scss"></style>
