<template>
  <div class="h-100">
    <div v-if="!roundStarted" class="row h-100 w-100">
      <div class="row w-100">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <div class="alert alert-warning">
            You must add at least 1 player and configure the course before you
            can start scoring the round
          </div>
        </div>
      </div>
      <div class="row w-100">
        <div
          class="col-12 col-sm-12"
          :class="{ 'col-md-6': !hasCourse, 'col-md-12': hasCourse }"
          v-if="!hasPlayers"
        >
          <router-link :to="{ path: 'players', query: { add: true } }">
            <b-button class="btn btn-success w-100 m-2">
              Configure Players
            </b-button>
          </router-link>
        </div>
        <div
          class="col-12 col-sm-12"
          :class="{ 'col-md-6': !hasPlayers, 'col-md-12': hasPlayers }"
          v-if="!hasCourse"
        >
          <router-link :to="{ path: 'course', query: { add: true } }">
            <b-button class="btn btn-success w-100 m-2">
              Configure Course
            </b-button>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="col-12 col-lg-12 col-xl-8 scorecard">
      <b-form>
        <h4 class="text-center">{{ currentHoleName }}</h4>
        <div class="text-center">
          <p class="text-muted p-0 m-0">
            Stroke Index {{ currentHoleStrokeIndex }}
          </p>
          <p class="text-muted p-0 m-0">Par {{ currentHolePar }}</p>
        </div>
        <div class="row no-gutters w-100">
          <div class="col-12 col-sm-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="m-1">
              <b-button
                :disabled="!canGoBack"
                @click="moveToPreviousHole"
                class="w-100"
                variant="dark"
                >Previous Hole</b-button
              >
            </div>
          </div>
          <div class="col-12 col-sm-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="m-1">
              <b-button @click="skipHole" class="w-100" variant="danger"
                >Skip Hole</b-button
              >
            </div>
          </div>
          <div class="col-12 col-sm-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="m-1">
              <b-button
                :disabled="!canGoForward"
                @click="moveToNextHole"
                variant="dark"
                class="w-100"
                >Next Hole</b-button
              >
            </div>
          </div>
        </div>
        <div
          v-for="(playerInfo, index) in scorecard.players"
          :key="index"
          class="row no-gutters w-100 justify-content-center player-scorecard"
        >
          <h4 class="text-center w-100">{{ playerInfo.name }}</h4>
          <div class="row no-gutters w-100 mt-3">
            <div class="row no-gutters w-100">
              <div class="row no-gutters w-100 mb-1 justify-content-center">
                <b-button
                  variant="dark"
                  class="col-6"
                  :disabled="disableScoreAdjuster(false, playerInfo)"
                  @click="adjustScore(false, playerInfo)"
                  >-</b-button
                >
              </div>

              <div class="row no-gutters w-100 justify-content-center">
                <div
                  class="col-6 text-center"
                  :class="{
                    'pick-up': hasPickedUp(playerInfo),
                    skipped: hasBeenSkipped(playerInfo),
                  }"
                >
                  <input
                    type="number"
                    v-model="getPlayerScore(playerInfo).score"
                    :disabled="!canSetScore(playerInfo)"
                    class="score-input"
                    min="0"
                    max="10"
                    @input="
                      onPlayerScoreChanged($event.target.value, playerInfo)
                    "
                  />
                </div>
              </div>

              <div class="row no-gutters w-100 mt-1 justify-content-center">
                <b-button
                  variant="dark"
                  class="col-6"
                  @click="adjustScore(true, playerInfo)"
                  :disabled="disableScoreAdjuster(true, playerInfo)"
                  >+</b-button
                >
              </div>
            </div>

            <div class="row no-gutters mt-3 w-100 justify-content-center">
              <b-button
                v-if="!hasBeenSkipped(playerInfo)"
                variant="info"
                class="col-8 col-sm-6 mt-2"
                @click="skipped(playerInfo)"
                :disabled="!canSkip(playerInfo)"
                >Skipped</b-button
              >
              <b-button
                v-else
                variant="success"
                class="col-8 col-sm-6 mt-2"
                @click="resetSkipped(playerInfo)"
                >Set Score</b-button
              >
            </div>

            <div
              class="row no-gutters w-100 text-center justify-content-center"
            >
              <b-button
                v-if="!hasPickedUp(playerInfo)"
                variant="danger"
                class="col-8 col-sm-6 mt-2"
                @click="pickUp(playerInfo)"
                :disabled="!canPickup(playerInfo)"
                >Pick Up</b-button
              >
              <b-button
                v-else
                variant="success"
                class="col-8 col-sm-6 mt-2"
                @click="resetPickup(playerInfo)"
                >Set Score</b-button
              >
            </div>
            <div
              class="row no-gutters m-3 w-100 text-center justify-content-center"
            >
              <div class="w-100">
                <b>Par:</b>
                {{ getPlayerPar(playerInfo) }}
              </div>
              <div class="w-100">
                <b>Points:</b> {{ getPlayerPoints(playerInfo) }}
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "MatchScorecard",
  methods: {
    canSetScore(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      const { pickUp, skipped } = hole;
      if (pickUp || skipped) {
        return false;
      }
      return true;
    },

    canPickup(playerInfo) {
      return this.canSetScore(playerInfo);
    },

    canSkip(playerInfo) {
      return this.canSetScore(playerInfo);
    },

    hasPickedUp(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      if (hole.pickUp) {
        return true;
      }
      return false;
    },

    hasBeenSkipped(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      if (hole.skipped) {
        return true;
      }
      return false;
    },

    skipped(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      hole.score = 0;
      hole.points = 0;
      hole.skipped = true;
    },

    pickUp(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      hole.score = 10;
      hole.points = 0;
      hole.pickUp = true;
    },

    resetSkipped(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      hole.score = 0;
      hole.points = 0;
      hole.skipped = false;
    },

    resetPickup(playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      hole.score = 0;
      hole.points = 0;
      hole.pickUp = false;
    },

    getPlayerPoints(playerInfo) {
      return playerInfo.holes[this.currentHole].points;
    },

    getPlayerPar(playerInfo) {
      return playerInfo.holes[this.currentHole].par;
    },

    getPlayerScore(playerInfo) {
      return playerInfo.holes[this.currentHole];
    },

    disableScoreAdjuster(increment, playerInfo) {
      if (!this.canSetScore(playerInfo)) {
        return true;
      }

      const hole = playerInfo.holes[this.currentHole];
      const { score } = hole;

      if (!increment) {
        return score === 1;
      } else {
        return score === 10;
      }
    },

    adjustScore(increment, playerInfo) {
      const holeScore = playerInfo.holes[this.currentHole];
      if (increment) {
        holeScore.score += 1;
      } else {
        holeScore.score -= 1;
      }
      this.onPlayerScoreChanged(holeScore.score, playerInfo);
    },

    skipHole() {
      for (let i = 0; i < this.scorecard.players.length; i++) {
        const hole = this.scorecard.players[i].holes[this.currentHole];
        hole.points = 0;
        hole.score = 0;
        hole.skipped = true;
        hole.pickUp = false;
      }
      this.moveToNextHole();
    },

    onPlayerScoreChanged(score, playerInfo) {
      const hole = playerInfo.holes[this.currentHole];
      const { par } = hole;
      const delta = par - parseInt(score);
      let points = 0;

      if (delta >= -1) {
        points = delta + 2;
      }

      hole.score = parseInt(score);
      hole.points = points;
    },

    moveToNextHole() {
      this.currentHole += 1;
    },

    moveToPreviousHole() {
      this.currentHole -= 1;
    },
  },

  computed: {
    ...Vuex.mapGetters(["players", "course", "scorecard"]),

    roundStarted() {
      if (this.hasCourse && this.hasPlayers) {
        return true;
      } else {
        return false;
      }
    },

    currentHoleName() {
      return `Hole ${this.currentHole + 1}`;
    },

    currentHolePar() {
      return this.course.holes[this.currentHole].par;
    },

    currentHoleStrokeIndex() {
      return this.course.holes[this.currentHole].strokeIndex;
    },

    currentHoleYards() {
      return this.course.holes[this.currentHole].yards;
    },

    canGoBack() {
      return this.currentHole > 0;
    },

    canGoForward() {
      return this.currentHole < this.course.holes.length - 1;
    },

    hasPlayers() {
      if (this.players && this.players.length >= 1) {
        return true;
      }
      return false;
    },

    hasCourse() {
      if (this.course) {
        return true;
      }
      return false;
    },

    holeInfoFields() {
      return ["name", "par", "score", "points", "actions"];
    },
  },
  data() {
    return {
      currentHole: 0,
    };
  },
};
</script>

<style scoped lang="scss">
.pick-up {
  border: solid black 5px;
  outline: 5px solid black;
  outline-offset: -10px;
  padding: 10px;
}

.skipped {
  border: dashed black 5px;
  padding: 5px;
}

.scorecard {
  .player-scorecard {
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top: solid black 1px;
  }
}

.score-input {
  height: 50px;
  font-size: 50px;
  text-align: center;
}
</style>
