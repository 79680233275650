var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[(_vm.displayWarning)?_c('div',{staticClass:"row justify-content-center align-items-center h-100 w-100"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"},[_c('div',{staticClass:"alert alert-warning text-center"},[_vm._v(" Please add one or more players before starting the round ")]),(!_vm.showPlayerForm)?_c('div',{staticClass:"m text-center"},[_c('b-button',{staticClass:"btn btn-success w-100",on:{"click":_vm.addplayer}},[_vm._v("Add Player")])],1):_vm._e()])]):_c('div',{staticClass:"row no-gutters w-100 justify-content-center align-items-center"},[(_vm.showPlayerForm)?_c('div',{staticClass:"h-50 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8"},[_c('b-form',{staticClass:"playerForm"},[_c('h4',{staticClass:"text-center mb-3"},[(!_vm.editingPlayer)?_c('span',[_vm._v("Add Player to Group")]):_c('span',[_vm._v("Edit Player")])]),_c('b-form-group',{attrs:{"label":"Enter Player Name","label-for":"player-name"}},[_c('b-input',{attrs:{"id":"player-name","type":"text"},model:{value:(_vm.player.name),callback:function ($$v) {_vm.$set(_vm.player, "name", $$v)},expression:"player.name"}})],1),_c('b-form-group',{attrs:{"label":"Enter Handicap","label-for":"player-handicap"}},[_c('b-input',{attrs:{"id":"player-handicap","type":"number","min":"0","max":"100"},model:{value:(_vm.player.handicap),callback:function ($$v) {_vm.$set(_vm.player, "handicap", $$v)},expression:"player.handicap"}}),_c('div',{staticClass:"m-auto slider"},[_c('b-form-input',{attrs:{"type":"range","min":"0","max":"100"},model:{value:(_vm.player.handicap),callback:function ($$v) {_vm.$set(_vm.player, "handicap", $$v)},expression:"player.handicap"}})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 mt-2",class:{
              'col-md-6 col-lg-6': _vm.editingPlayer,
              'col-md-4 col-lg-4': !_vm.editingPlayer,
            }},[_c('b-button',{staticClass:"w-100 btn btn-danger",on:{"click":_vm.cancelAddPlayer}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"col-12 col-sm-12 mt-2",class:{
              'col-md-6 col-lg-6': _vm.editingPlayer,
              'col-md-4 col-lg-4': !_vm.editingPlayer,
            }},[_c('b-button',{staticClass:"w-100 btn btn-succes",attrs:{"disabled":!_vm.canSave,"variant":"primary"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("Save")])],1),(!_vm.editingPlayer)?_c('div',{staticClass:"col-12 col-sm-12 col-md-4 col-lg-4 mt-2"},[_c('b-button',{staticClass:"w-100 btn btn-success",attrs:{"disabled":!_vm.canSave},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("Save & Add New")])],1):_vm._e()])],1)],1):_vm._e(),(_vm.hasPlayers)?_c('div',{staticClass:"row w-100 justify-content-center",class:{ 'h-50': _vm.showPlayerForm, 'h-100': !_vm.showPlayerForm }},[_c('div',{staticClass:"h-100 pt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8"},[_c('h4',{staticClass:"text-center"},[_vm._v("Current Group")]),(!_vm.showPlayerForm)?_c('div',{staticClass:"mt-3 mb-3 text-center"},[_c('b-button',{staticClass:"btn btn-success w-100",on:{"click":_vm.addplayer}},[_vm._v("Add Player")])],1):_vm._e(),_c('div',[_c('b-table',{staticClass:"table",attrs:{"striped":"","items":_vm.matchPlayers,"fields":_vm.playerFields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('div',[_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"m-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.editPlayer(data.item.id)}}},[_c('b-icon-pencil')],1),_c('b-button',{staticClass:"m-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removePlayer(data.item.id)}}},[_c('b-icon-trash')],1)],1)])]}}],null,false,880273132)})],1)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }