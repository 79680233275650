<template>
  <div class="h-100">
    <div
      v-if="course"
      class="row justify-content-center align-items-center h-100 w-100"
    >
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 m-auto">
        <h4 class="text-center m-3">Course Summary</h4>
        <div>
          <div>
            <span class="font-weight-bold">Course Name: </span>{{ courseName }}
          </div>
          <div>
            <span class="font-weight-bold">Course Par: </span>{{ coursePar }}
          </div>
          <div>
            <span class="font-weight-bold">Slope Index: </span
            >{{ courseSlopeRating }}
          </div>
          <div>
            <span class="font-weight-bold">Course Rating: </span
            >{{ courseRating }}
          </div>
          <div><span class="font-weight-bold">Par: </span>{{ coursePar }}</div>
        </div>
        <h4 class="m-3 text-center">Holes</h4>
        <b-table
          striped
          class="table text-center"
          :items="course.holes"
        ></b-table>
      </div>
    </div>
    <div
      v-else
      class="row no-gutters w-100 h-100 align-items-center justify-content-center"
    >
      <div v-if="!configuringCourse">
        <div class="alert alert-warning">
          The course has not been configured. Please configure before starting
          the round
        </div>
        <div class="text-center">
          <b-button class="btn btn-success w-100" @click="configureCourse">
            Configure Course
          </b-button>
        </div>
      </div>
      <div v-else class="h-100 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.course-details-accordion variant="info"
              >Course Details</b-button
            >
          </b-card-header>
          <b-collapse
            id="course-details-accordion"
            :visible="!configuringHoles"
            accordion="course-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-form>
                <b-form-group label="Course Name" label-for="course-name">
                  <b-input
                    v-model="newCourse.name"
                    id="course-name"
                    type="text"
                  >
                  </b-input>
                </b-form-group>

                <b-form-group
                  label="Round Type"
                  label-for="round-type"
                  class="mt-2"
                >
                  <b-form-select v-model="newCourse.roundType" class="mb-3">
                    <b-form-select-option :value="null"></b-form-select-option>
                    <b-form-select-option value="f9"
                      >Front 9</b-form-select-option
                    >
                    <b-form-select-option value="b9"
                      >Back 9</b-form-select-option
                    >
                    <b-form-select-option value="f18"
                      >Full 18</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>

                <b-form-group label="Course Par" label-for="course-name">
                  <b-input
                    v-model="newCourse.coursePar"
                    id="course-par"
                    type="number"
                    :disabled="!canSetCourseDetail"
                  >
                  </b-input>

                  <b-form-input
                    v-model="newCourse.coursePar"
                    type="range"
                    min="18"
                    max="100"
                    :disabled="!canSetCourseDetail"
                    step="1"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Course Rating" label-for="course-rating">
                  <b-input
                    v-model="newCourse.courseRating"
                    id="course-rating"
                    type="number"
                    :disabled="!canSetCourseDetail"
                  >
                  </b-input>
                  <b-form-input
                    v-model="newCourse.courseRating"
                    type="range"
                    min="18"
                    max="100"
                    :disabled="!canSetCourseDetail"
                    step="0.1"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Slope Rating"
                  label-for="course-slope-rating"
                  class="mt-2"
                >
                  <b-input
                    v-model="newCourse.slopeRating"
                    id="course-slope-rating"
                    type="number"
                    :disabled="!canSetCourseDetail"
                  >
                  </b-input>
                  <b-form-input
                    v-model="newCourse.slopeRating"
                    type="range"
                    min="18"
                    max="100"
                    :disabled="!canSetCourseDetail"
                    step="1"
                  ></b-form-input>
                </b-form-group>

                <div class="w-100">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-4 ml-auto">
                    <b-button
                      class="w-100 btn btn-success"
                      :disabled="!canConfigureHoles"
                      @click="configureHoles"
                    >
                      Next
                    </b-button>
                  </div>
                </div>
              </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1" v-if="configuringHoles">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.holes-accordion variant="info"
              >Holes</b-button
            >
          </b-card-header>
          <b-collapse
            id="holes-accordion"
            accordion="course-accordion"
            role="tabpanel"
            :visible="configuringHoles"
          >
            <b-card-body>
              <div v-if="configuringHoles">
                <h4 class="mt-3">{{ currentHole.name }}</h4>
                <b-form-group label="Yardage" label-for="course-name">
                  <b-input
                    v-model="currentHole.yardage"
                    id="course-name"
                    type="text"
                  >
                  </b-input>
                </b-form-group>
                <b-form-group label="Par" label-for="course-name">
                  <b-input
                    v-model="currentHole.par"
                    id="course-name"
                    type="text"
                  >
                  </b-input>
                </b-form-group>
                <b-form-group label="Stroke Index" label-for="course-name">
                  <b-input
                    v-model="currentHole.strokeIndex"
                    id="course-name"
                    type="text"
                  >
                  </b-input>
                </b-form-group>
                <div class="row mt-2">
                  <div class="col-6 col-lg-6">
                    <b-button
                      class="w-100 btn btn-success"
                      :disabled="!showPreviousHole"
                      @click="moveToPreviousHole"
                    >
                      Previous Hole
                    </b-button>
                  </div>
                  <div class="col-6 col-lg-6 m-auto">
                    <b-button
                      class="w-100 btn btn-success"
                      :disabled="!showNextHole"
                      @click="moveToNextHole"
                    >
                      Next Hole
                    </b-button>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <div class="row mt-2">
          <div class="col-12 col-xd-6 ml-auto mr-auto mt-2">
            <b-button class="w-100 btn btn-success" @click="saveCourse">
              Save
            </b-button>
          </div>
          <div class="col-12 col-xd-6 ml-auto mr-auto mt-2">
            <b-button
              class="btn w-100 btn-danger"
              @click="cancelConfigureCourse"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "MatchVenue",
  methods: {
    configureHoles() {
      this.configuringHoles = true;
    },

    saveCourse() {
      this.$store.dispatch("saveCourse", this.newCourse);
    },

    configureCourse() {
      this.configuringCourse = true;
    },

    cancelConfigureCourse() {
      this.configuringCourse = false;
      this.resetCourse();
    },

    resetCourse() {
      this.newCourse = {
        name: null,
        holes: [],
        courseRating: 70,
        slopeRating: 113,
        coursePar: null,
        roundType: null,
      };
    },

    generateCourseHolesData(startHole, playing) {
      const holes = [];
      const offset = startHole;
      for (let i = 0; i < playing; i++) {
        const hole = {
          name: `Hole ${offset + i}`,
          yardage: 0,
          par: 3,
          strokeIndex: 1,
        };
        holes.push(hole);
        startHole + 1;
      }
      return holes;
    },

    moveToNextHole() {
      this.currentHoleIndex += 1;
    },

    moveToPreviousHole() {
      this.currentHoleIndex -= 1;
    },
  },
  watch: {
    "newCourse.roundType": function (val) {
      if (val) {
        switch (val) {
          case "b9":
            this.newCourse.coursePar = 36;
            this.newCourse.holes = this.generateCourseHolesData(1, 9);
            break;
          case "f9":
            this.newCourse.coursePar = 36;
            this.newCourse.holes = this.generateCourseHolesData(10, 9);
            break;
          default:
            this.newCourse.coursePar = 72;
            this.newCourse.holes = this.generateCourseHolesData(1, 18);
            break;
        }
      } else {
        this.newCourse.coursePar = null;
      }
    },
  },

  computed: {
    ...Vuex.mapGetters(["course"]),

    canSetCourseDetail() {
      if (this.newCourse.roundType) {
        return true;
      }
      return false;
    },

    canConfigureHoles() {
      if (
        this.newCourse.courseRating > 0 &&
        this.newCourse.slopeRating > 0 &&
        this.newCourse.roundType &&
        this.newCourse.coursePar > 0 &&
        this.newCourse.name
      ) {
        return true;
      }
      return false;
    },

    currentHole() {
      return this.newCourse.holes[this.currentHoleIndex];
    },

    showNextHole() {
      console.log(this.currentHoleIndex < this.newCourse.holes.length);
      console.log(this.currentHoleIndex);

      console.log(this.newCourse.holes.length);
      return this.currentHoleIndex < this.newCourse.holes.length - 1;
    },

    showPreviousHole() {
      return this.currentHoleIndex > 0;
    },

    courseName() {
      if (this.course) {
        return this.course.name;
      }
      return null;
    },

    courseSlopeRating() {
      if (this.course) {
        return this.course.slopeRating;
      }
      return null;
    },

    courseRating() {
      if (this.course) {
        return this.course.courseRating;
      }
      return null;
    },

    coursePar() {
      if (this.course) {
        return this.course.coursePar;
      }
      return null;
    },
  },
  data() {
    return {
      configuringCourse: false,
      configuringHoles: false,
      newCourse: {
        name: null,
        holes: [],
        courseRating: 70,
        slopeRating: 113,
        coursePar: null,
        roundType: null,
      },
      currentHoleIndex: 0,
      configured: false,
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.add === "true") {
      this.configuringCourse = true;
    }
  },
};
</script>

<style scoped>
.course-summary {
  border: solid black 1px;
  padding: 10px;
  border-radius: 10px;
}
</style>
