import Vue from "vue";
import VueRouter from "vue-router";
import MatchPlayers from "../components/MatchPlayers";
import MatchScorecard from "../components/MatchScorecard";
import MatchVenue from "../components/MatchVenue";
import MatchLeaderboard from "../components/MatchLeaderboard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MatchPlayers",
    component: MatchPlayers,
  },
  {
    path: "/players",
    name: "MatchPlayers",
    component: MatchPlayers,
  },
  {
    path: "/course",
    name: "MatchVenue",
    component: MatchVenue,
  },
  {
    path: "/scorecard",
    name: "MatchScorecard",
    component: MatchScorecard,
  },
  {
    path: "/leaderboard",
    name: "MatchLeaderboard",
    component: MatchLeaderboard,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
