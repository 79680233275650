<template>
  <div id="app">
    <div class="header">
      <div>
        <b-navbar toggleable="sm">
          <b-navbar-brand to="/">SH-2023</b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item to="/players">Players</b-nav-item>
              <b-nav-item to="/course">Course</b-nav-item>
              <b-nav-item to="/scorecard">Scorecard</b-nav-item>
              <b-nav-item to="/leaderboard">Leaderboard</b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <div class="tile">
      <div class="container-fluid h-100">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html,
body {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  overflow: auto;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
  background: white;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: grey;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100%;
  background: url(./assets/background-no-logo.jpg) no-repeat center fixed;
  background-size: cover;
}

.tile {
  background-color: white;
  padding: 20px 10px;
  height: 90%;
  width: 88%;
  margin: auto auto auto auto;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
}
</style>

<script>
export default {
  name: "App",
};
</script>
