import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function generatePlayerScoreCard(course, player) {
  let holes = [];
  for (let i = 0; i < course.holes.length; i++) {
    const hole = course.holes[i];
    const { strokeIndex, par } = hole;
    const playerHandicap = player.handicap;
    let holeHandicap = parseInt(playerHandicap / 18, 10);
    let stokeIndexHandicap = parseInt(playerHandicap % 18, 10);
    if (stokeIndexHandicap <= strokeIndex) {
      holeHandicap += 1;
    }
    const holeScore = {
      par: par + holeHandicap,
      points: 0,
      score: 0,
      pickUp: false,
      skipped: false,
    };
    holes.push(holeScore);
  }
  const playerScorecard = {
    name: player.name,
    points: 0,
    handicap: player.handicap,
    holes,
  };
  return playerScorecard;
}

export default new Vuex.Store({
  state: {
    players: [],
    course: null,
    scorecard: {
      players: [],
    },
  },
  getters: {
    players: (state) => state.players,
    course: (state) => state.course,
    scorecard: (state) => state.scorecard,
  },
  mutations: {
    setCourse(state, course) {
      state.course = course;
    },

    editPlayer(state, player) {
      const oldPlayer = state.players.filter((p) => p.id === player.id)[0];
      oldPlayer.name = player.name;
      oldPlayer.handicap = player.handicap;
    },

    setPlayer(state, player) {
      const id = state.players.length;
      player.id = id;
      state.players.push({
        ...player,
      });
    },

    generateScoreCard(state, players) {
      for (let i = 0; i < players.length; i++) {
        const player = players[i];
        const playerScorecard = generatePlayerScoreCard(state.course, player);
        state.scorecard.players.push(playerScorecard);
      }
    },

    generatePlayerScoreCard(state, player) {
      if (state.course) {
        const playerScorecard = generatePlayerScoreCard(state.course, player);
        state.scorecard.players.push(playerScorecard);
      }
    },

    removePlayer(state, playerId) {
      const index = state.players.findIndex((p) => p.id === playerId);
      state.players = state.players.toSpliced(index, 1);
    },
  },
  actions: {
    addPlayer({ commit }, player) {
      commit("setPlayer", player);
      commit("generatePlayerScoreCard", player);
    },

    editPlayer({ commit }, player) {
      commit("editPlayer", player);
    },

    removePlayer({ commit }, playerId) {
      commit("removePlayer", playerId);
    },

    saveCourse({ state, commit }, course) {
      commit("setCourse", course);
      commit("generateScoreCard", state.players);
    },
  },
  modules: {},
});
